import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import purpose from '../json/campaign-purpose.json';
import '../../src/assets/scss/purpose.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Purpose() {
  return (
    <div className='bg-navyGreen'>
      <Container className='px-lg-5'>
        <div className='py-4 pt-lg-5'>
          <div className='d-flex fnt-14 fnt-lg-18 fnt-md-16 fnt-md-18 fw-700 justify-content-center text-uppercase textColor'>
            Our Purpose
          </div>
        </div>

        <Row>
          {purpose.text.map((item, index) => (
            <Col key={index} xs={12} md={6} lg={6} className='px-0'>
              <div className='mb-lg-4 purposeCard'>
                <Card className='h-100 common-card-effect cardPurposeBorder'>
                  <Card.Body className=''>
                    <Card.Title className='d-flex'>
                      <LazyLoadImage effect='blur' src={item.icon} alt={item.header} />
                      <div className='d-flex align-items-center ms-3'>
                        <div className='fw-700 fnt-18 '>{item.header}</div>
                      </div>
                    </Card.Title>
                    <Card.Text className='fnt-14 fnt-lg-16 mt-4'>{item.headertext}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
