import React from 'react';
import { Carousel, Container, Row } from 'react-bootstrap';
import successStoryValues from '../json/success-story.json';
import '../../src/assets/scss/CaseStudy.scss';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function caseStudy() {
  return (
    <div className='bg-navyGreen py-4 pt-lg-5'>
      <Container>
        <div className=''>
          <h3 className='fw-700 fnt-lg-18 fnt-md-16 fnt-14 mb-4 color-lightGreen text-center text-uppercase'>
            Case Studies
          </h3>
        </div>
        <Carousel>
          {successStoryValues.map((item) => (
            <Carousel.Item key={item.id}>
              <Link to={`/success-stories/${item.slug}`}>
                <Row className='mb-5'>
                  <div className='align-items-center col-12 col-lg-3 col-md-3 d-lg-flex justify-content-lg-center d-md-flex justify-content-md-center mb-3 mb-lg-0 pt-lg-5'>
                    <LazyLoadImage
                      effect='blur'
                      src={item.headerImg}
                      className='w-50'
                      alt={item.name}
                      width={'100%'}
                    />
                  </div>
                  <div className='col-lg-8 col-md-8 col-12 pt-lg-3'>
                    <div className='d-flex'>
                      <div className='me-3'>
                        <LazyLoadImage
                          effect='blur'
                          className='rounded-circle profileImageSuccessStory'
                          src={item.profileIcon}
                          alt={item.name}
                          width={'100%'}
                        />
                      </div>
                      <div className=''>
                        <div className='text-white fw-600 fnt-lg-16 fnt-14'>{item.name}</div>
                        <div className='text-white fw-400 fnt-14'>{item.designation}</div>
                      </div>
                    </div>
                    <div className='text-white fw-400 fnt-lg-18 fnt-14 mt-3'>{item.comment}</div>
                  </div>
                </Row>
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}
