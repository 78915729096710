import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ContactUsForm from './ContactUsForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import call from '../assets/images/call_black.svg';
import email from '../assets/images/email_black.svg';
import landline from '../assets/images/Landline_black.svg';
import location from '../assets/images/location_black.svg';

export default function CustomerSupport() {
  return (
    <Container className='py-lg-5 py-md-5 py-3'>
      <Row>
        <div className='col-12 col-lg-6 d-flex justify-content-center'>
          <div className='pt-4'>
            <div className=''>
              <div className='align-items-center mb-3'>
                <h3 className='fw-600 fnt-lg-24 fnt-16'>Customer Support</h3>
              </div>
              <div className=''>
                <ul className='fnt-lg-18 fnt-14 fw-400'>
                  <li className='d-flex mb-lg-2 mb-md-4'>
                    <div className='d-flex'>
                      <LazyLoadImage effect='blur' className='me-2' src={email} alt='Email Icon' />
                      <div>
                        <a className='text-black' href='mailto:contact@softobotics.com'>
                          contact@softobotics.com
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className='d-flex'>
                    <div className='d-flex pe-4'>
                      <LazyLoadImage
                        effect='blur'
                        className='me-2'
                        src={landline}
                        alt='Landline Icon'
                      />
                      <div className='fnt-14 fnt-lg-16'>+91 803-500-8966</div>
                    </div>
                  </li>
                  <li className='d-flex'>
                    <div className='d-flex pe-4'>
                      <LazyLoadImage effect='blur' className='me-2' src={call} alt='Mobile Icon' />
                      <div className='fnt-14 fnt-lg-16'>+91 948-388-2266 </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className=''>
              <div className='align-items-center d-flex justify-content-start'>
                <h3 className='fw-600 fnt-16 fnt-lg-24'>Address</h3>
              </div>
              <div className=''>
                <ul className='fnt-lg-18 fnt-14 fw-400 list-unstyled'>
                  <li>
                    If you prefer to send us mail or visit our physical location, you can find us at
                    the following address:
                  </li>
                  <li className=' mb-md-4 mb-lg-2'>
                    <div className='d-flex'>
                      <div>
                        {' '}
                        <span>
                          {' '}
                          <LazyLoadImage
                            effect='blur'
                            className='me-2'
                            src={location}
                            alt='Mobile Icon'
                          />
                        </span>
                        1st Floor, 563/1, Prerana Towers, Ranka Colony Rd, Munivenkatppa Layout,
                        Bilekahalli, Bengaluru, Karnataka 560076
                      </div>
                    </div>
                  </li>
                  <li>
                    Please note that visits to our physical location are by appointment only. To
                    schedule a visit, please contact us in advance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='fnt-lg-32 fnt-16 fw-700 mb-lg-4 mb-3'>
            We Would love to hear from you!
          </div>
          <div className='d-flex flex-column form-section justify-content-center p-3 p-lg-4'>
            <ContactUsForm />
          </div>
        </div>
      </Row>
    </Container>
  );
}
